export const routes = [
    {
        path: '/memberships',
        name: 'memberships.browse',
        component: () => import(/* webpackChunkName: "MembershipsBrowse" */ '@/views/app/MembershipsBrowse.vue'),
        meta: { guard: 'verified', roles: "adm, sup, asi" }
    },
    {
        path: '/memberships/:id/clone',
        name: 'memberships.clone',
        component: () => import(/* webpackChunkName: "MembershipsActions" */ '@/views/app/MembershipsActions.vue'),
        meta: { guard: 'verified', roles: "adm, sup, asi" }
    },
    {
        path: '/memberships/create',
        name: 'memberships.create',
        component: () => import(/* webpackChunkName: "MembershipsActions" */ '@/views/app/MembershipsActions.vue'),
        meta: { guard: 'verified', roles: "adm, sup, asi" }
    },
    {
        path: '/memberships/:id/edit',
        name: 'memberships.edit',
        component: () => import(/* webpackChunkName: "MembershipsActions" */ '@/views/app/MembershipsActions.vue'),
        meta: { guard: 'verified', roles: "adm, sup, asi" }
    },
    {
        path: '/memberships/:id/delete',
        name: 'memberships.delete',
        component: () => import(/* webpackChunkName: "MembershipsActions" */ '@/views/app/MembershipsActions.vue'),
        meta: { guard: 'verified', roles: "adm, sup" }
    },
    {
        path: '/memberships/:id/show',
        name: 'memberships.show',
        component: () => import(/* webpackChunkName: "MembershipsActions" */ '@/views/app/MembershipsActions.vue'),
        meta: { guard: 'verified', roles: "adm, sup, asi" }
    },
]